import React from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Testimonials from "./components/Testimonials";
import "./styles/App.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Faqs from "./components/Faqs";

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <div className="section" id="home">
          <Home />
        </div>
        <div className="section" id="about">
          <About />
        </div>
        <div className="section" id="services">
          <Services />
        </div>
        <div className="section" id="testimonials">
          <Testimonials />
        </div>
        <div className="section" id="faqs">
          <Faqs />
        </div>
        <div className="sectionBottom" id="contact">
          <Contact />
        </div>
      </div>
    </Router>
  );
}

const NavBar = () => {
  const { hash } = useLocation();

  return (
    <nav>
      <div className="fixed-nav">
        <h1 className="nav-header-text">HARSH SOLAR POWER SOLUTION</h1>

        <ul className="nav-header-ul">
          <li className={hash === "#home" || hash === "" ? "active" : ""}>
            <Link smooth to="#home">
              Home
            </Link>
          </li>
          <li className={hash === "#about" ? "active" : ""}>
            <Link smooth to="#about">
              About Us
            </Link>
          </li>
          <li className={hash === "#services" ? "active" : ""}>
            <Link smooth to="#services">
              Services
            </Link>
          </li>
          <li className={hash === "#testimonials" ? "active" : ""}>
            <Link smooth to="#testimonials">
              Testimonials
            </Link>
          </li>
          <li className={hash === "#faqs" ? "active" : ""}>
            <Link smooth to="#faqs">
              Faqs
            </Link>
          </li>
          <li className={hash === "#contact" ? "active" : ""}>
            <Link smooth to="#contact">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default App;

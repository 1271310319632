import React from "react";
import "../styles/Testimonials.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const images = [
    // {
    //   id: 1,
    //   src: "https://lh3.googleusercontent.com/p/AF1QipMNcAX4XHzpz7IJfsH8VEK5Fk9ZKhkQqPOwa8B9=s1360-w1360-h1020",
    //   alt: "Solar Product 1",
    // },
    // {
    //   id: 2,
    //   src: "https://lh3.googleusercontent.com/p/AF1QipNeM0wYdLVBC7gf3DGApIsXwHnT3IwSq7203mGx=s1360-w1360-h1020",
    //   alt: "Solar Product 2",
    // },
    // {
    //   id: 3,
    //   src: "https://lh3.googleusercontent.com/p/AF1QipNcA-NXIdxdPJD7w63jTuQLVx8tiLycfZ9lisuM=s1360-w1360-h1020",
    //   alt: "Solar Product 3",
    // },
    {
      id: 4,
      src: "https://lh3.googleusercontent.com/p/AF1QipN2CHXOxO9f5e2Q4NFp1o8KJDneD0WfD77pZ2VE=s1360-w1360-h1020",
      alt: "Solar Product 4",
    },
    {
      id: 5,
      src: "https://lh3.googleusercontent.com/p/AF1QipOdxFfZwK2Jpe1_W3mQzbI3D9rkkyt-VCI3tj94=s1360-w1360-h1020",
      alt: "Solar Product 5",
    },
    {
      id: 6,
      src: "https://lh3.googleusercontent.com/p/AF1QipPY3xHj5-Wr-Jed5gW77y9M83eWRTjknAIlfrti=s1360-w1360-h1020",
      alt: "Solar Product 6",
    },
    {
      id: 7,
      src: "https://lh3.googleusercontent.com/p/AF1QipPFaYEAV3A4X4j2xVpf_OvNNxPl2ICRP9LQlXqz=s1360-w1360-h1020",
      alt: "Solar Product 7",
    },
    {
      id: 8,
      src: "https://lh3.googleusercontent.com/p/AF1QipNvxp0WSfY0u8KcT3b2l7a1KF1CT7Ki56jLX-n8=s1360-w1360-h1020",
      alt: "Solar Product 8",
    },
    {
      id: 9,
      src: "https://lh3.googleusercontent.com/p/AF1QipNmnW2XYJG1XCkQuwML0axnAwjsFCcOaWKnrxf8=s1360-w1360-h1020",
      alt: "Solar Product 9",
    },
    {
      id: 10,
      src: "https://lh3.googleusercontent.com/p/AF1QipOUzYFiJvTiTD3i62JaYeBV9a0RvNBfZx77uf8a=s1360-w1360-h1020",
      alt: "Solar Product 10",
    },
    {
      id: 11,
      src: "https://lh3.googleusercontent.com/p/AF1QipN45j9xNJUVlRaHgX8MWsYlv2nZOACIDWGA8e3z=s1360-w1360-h1020",
      alt: "Solar Product 11",
    },
    {
      id: 12,
      src: "https://lh3.googleusercontent.com/p/AF1QipPvrOTWh5BXxabSMN4y_Ut4qTjBg5O_yskST4Lq=s1360-w1360-h1020",
      alt: "Solar Product 12",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <section className="containerTestimonials">
      <h1 className="headerTestimonials">Our Projects</h1>
      {/* <p className="paragraphTestimonials">"Harsh Solar Power Solution provided exceptional service and helped us reduce our energy bills significantly!" - Satisfied Customer</p> */}
      <div className="carousel-container-t">
        <Slider {...settings}>
          {images.map((image) => (
            <div key={image.id} >
              <img
                src={image.src}
                alt={image.alt}
                className="carousel-image-t"
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;

import React from "react";
import "../styles/Faqs.css";

const Faqs = () => {
  return (
    <section className="bodyContainerFaqs">
      <div>
        <h1 className="headerTitleFaqs">FAQs about Solar Energy</h1>
        <p className="paragraphFaqs">
          Q1: Will Solar Panels Work During Cloudy Days?{" "}
        </p>
        <p className="paragraphFaqsAnswers">
          Yes, solar panels can still generate electricity on cloudy days,
          although their efficiency might be reduced. They rely on sunlight, not
          heat.{" "}
        </p>
        <p className="paragraphFaqs">
          Q2: How Much Can I Save with Solar Panels?
        </p>
        <p className="paragraphFaqsAnswers">
          Savings depend on factors like energy consumption, local electricity
          rates, and system size. On average, households save thousands of
          dollars over the system’s lifespan.{" "}
        </p>
        <p className="paragraphFaqs">
          Q3: Is Solar Energy Suitable for My Location?
        </p>
        <p className="paragraphFaqsAnswers">
          Most places receive enough sunlight to make solar a viable option. Our
          team can conduct a free assessment to determine if solar energy is
          right for you.{" "}
        </p>
        <p className="paragraphFaqs">Q4: What Happens to Excess Energy? </p>
        <p className="paragraphFaqsAnswers">
          Excess energy can be sent back to the grid, where you may earn credits
          (net metering) or stored in batteries for later use.
        </p>
      </div>
    </section>
  );
};

export default Faqs;
